import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/tools",
  },
  {
    path: "/templates",
    name: "templates",
    component: () =>
      import(/* webpackChunkName: "templates" */ "../views/Templates.vue"),
  },
  {
    path: "/classroom",
    name: "classroom",
    component: () =>
      import(/* webpackChunkName: "classroom" */ "../views/Classroom.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const cognitoLoginUrl =
  "https://datalabwerfen.auth.us-east-1.amazoncognito.com/login?client_id=1o1ulbh6vlp6ssaga38mqq4b9d&response_type=token&scope=email+openid+profile&redirect_uri=https%3A%2F%2Fdatalab.werfen.com";

// Función para obtener el token de la URL
function getAccessTokenFromUrl(): string | null {
  const hash = window.location.hash;
  const params = new URLSearchParams(hash.replace("#", ""));
  return params.get("access_token");
}

// Guardar el token en localStorage al cargar la aplicación
const accessTokenFromUrl = getAccessTokenFromUrl();
if (accessTokenFromUrl) {
  localStorage.setItem("access_token", accessTokenFromUrl);
  // Limpia el hash para evitar que el token sea visible en la URL
  window.history.replaceState(null, "", window.location.pathname);
}

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) {
    window.location.href = cognitoLoginUrl;
  } else {
    next();
  }
});

export default router;
